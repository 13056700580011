import React, { useEffect, useState } from 'react';
import AIMLEditorContext from './context';

const AIMLEditorContextProvider = ({ children }: any) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [editorCols, setEditorCols] = useState<string>('col-sm-12');
  const [sourceNodeId, setSourceNodeId] = useState<any>(null);
  const [sideBarPos, setSideBarPos] = useState<number>(-300);
  useEffect(() => {
    if (showSideBar) {
      setSideBarPos(0);
    } else {
      setSideBarPos(-300);
    }
  }, [showSideBar]);
  const onShowSideBar = (id: any) => {
    setSourceNodeId(id);
    setShowSideBar(true);
    setEditorCols(`col-sm-9`);
  };

  const onHideSideBar = () => {
    setSideBarPos(-300);
    setTimeout(() => {
      setSourceNodeId(null);
      setShowSideBar(false);
      setEditorCols('col-sm-12');
    }, 1000);
  };

  return (
    <AIMLEditorContext.Provider
      value={{
        sourceNodeId,
        sideBarPosition: sideBarPos,
        setSideBarPosition: setSideBarPos,
        showSideBar,
        onHideSideBar,
        editorCols,
        onShowSideBar,
      }}
    >
      {children}
    </AIMLEditorContext.Provider>
  );
};

export default AIMLEditorContextProvider;
