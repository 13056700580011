import React, { FC, useState } from 'react';
import { css } from '@emotion/css';
import { Tab, TabsBar, Icon, IconName, useStyles2, IconButton, Button, Modal, Card } from '@grafana/ui';
import { NavModel, NavModelItem, NavModelBreadcrumb, GrafanaTheme2 } from '@grafana/data';
import { PanelHeaderMenuItem } from 'app/features/dashboard/dashgrid/PanelHeader/PanelHeaderMenuItem';
import { approveWorkFlowJob, loadWorkFlowApprovals } from '../../../features/ansible/template/state/middleware';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../types';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

export interface Props {
  model: NavModel;
  goBackBtn?: () => void;
}

const SelectNav = ({ children, customCss }: { children: NavModelItem[]; customCss: string }) => {
  if (!children || children.length === 0) {
    return null;
  }

  const defaultSelectedItem = children.find((navItem) => {
    return navItem.active === true;
  });

  return (
    <div className={`gf-form-select-wrapper width-20 ${customCss}`}>
      <div className="dropdown">
        <div className="gf-form-input dropdown-toggle" data-toggle="dropdown">
          {defaultSelectedItem?.text}
        </div>
        <ul className="dropdown-menu dropdown-menu--menu">
          {children.map((navItem: NavModelItem) => {
            if (navItem.hideFromTabs) {
              // TODO: Rename hideFromTabs => hideFromNav
              return null;
            }
            return (
              <PanelHeaderMenuItem
                key={navItem.url}
                iconClassName={navItem.icon}
                text={navItem.text}
                href={navItem.url}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const Navigation = ({ children }: { children: NavModelItem[] }) => {
  if (!children || children.length === 0) {
    return null;
  }

  return (
    <nav>
      <SelectNav customCss="page-header__select-nav">{children}</SelectNav>
      <TabsBar className="page-header__tabs" hideBorder={true}>
        {children.map((child, index) => {
          return (
            !child.hideFromTabs && (
              <Tab
                label={child.text}
                active={child.active}
                key={`${child.url}-${index}`}
                icon={child.icon as IconName}
                href={child.url}
              />
            )
          );
        })}
      </TabsBar>
    </nav>
  );
};

export const PageHeader: FC<Props> = ({ model, goBackBtn }) => {
  const styles = useStyles2(getStyles);
  const notifications = useSelector((state: StoreState) => state.ansibleTemplate.workFlowPendingList);
  const modalLoading = useSelector((state: StoreState) => state.ansibleTemplate.isFlowPendingListLoading);
  //const notificationsCount = useSelector((state: StoreState) => state.ansibleTemplate.workFlowPendingListCount);
  const dispatch = useDispatch();

  const [isApprovalsModal, setApprovalsModal] = useState<boolean>(false);
  if (!model) {
    return null;
  }
  const main = model.main;
  const children = main.children;

  const onJobApprovalClick = () => {
    dispatch(loadWorkFlowApprovals(5, 1));
    setApprovalsModal(true);
  };
  const onDismissModal = () => {
    toggleModal();
  };
  const toggleModal = () => {
    setApprovalsModal(!isApprovalsModal);
  };
  const renderApprovalsModal = () => {
    return (
      <Modal
        isOpen={isApprovalsModal}
        title="Notifications"
        onDismiss={onDismissModal}
        isBlock={modalLoading}
        style={{ overflowY: 'scroll', height: '1hv' }}
      >
        {notifications &&
          notifications.length > 0 &&
          notifications.map((i: any) => {
            return (
              <Card heading={`${i.id} - ${i.summary_fields.workflow_job.name}`} key={i.id}>
                <Card.Meta>
                  <div className="row">
                    <div className="col-sm-12">
                      <strong>APPROVAL</strong>&nbsp;&nbsp;&nbsp; {i.name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      {moment(i.started).format('MMMM Do YYYY, h:mm:ss a')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span style={{ color: '#ed2e2e' }}>
                        Expires: {moment(i.approval_expiration).format('MMMM Do YYYY, h:mm:ss a')}
                      </span>
                    </div>
                  </div>
                </Card.Meta>
                <Card.Actions>
                  <Button
                    key="settings"
                    variant="secondary"
                    onClick={() => {
                      dispatch(approveWorkFlowJob(i.id, 'approve', {}));
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    key="explore"
                    variant="destructive"
                    onClick={() => {
                      dispatch(approveWorkFlowJob(i.id, 'deny', {}));
                    }}
                  >
                    Deny
                  </Button>
                </Card.Actions>
              </Card>
            );
          })}
        {notifications && !modalLoading && notifications.length === 0 && (
          <Card heading="THERE ARE NO JOBS AWAITING APPROVAL"></Card>
        )}
        {/* <Pagination currentPage={currentPage} numberOfPages={10} onNavigate={(e) => {}} />*/}
      </Modal>
    );
  };
  return (
    <div className={styles.headerCanvas}>
      <div className="page-container">
        <div className="page-header">
          {renderHeaderTitle(main, onJobApprovalClick, goBackBtn)}
          {children && children.length && <Navigation>{children}</Navigation>}
        </div>
      </div>
      {renderApprovalsModal()}
    </div>
  );
};

function renderHeaderTitle(main: NavModelItem, jobNotifications: any, goBackBtn?: any) {
  const marginTop = main.icon === 'grafana' ? 12 : 14;
  return (
    <div className="page-header__inner row">
      <div className="col-sm-10">
        <div className="row">
          {goBackBtn && (
            <span className="m-r-1">
              <IconButton
                tooltip="Go back"
                tooltipPlacement="bottom"
                name="arrow-left"
                size="xxl"
                onClick={goBackBtn}
                style={{ marginTop }}
              />
            </span>
          )}
          <span className="page-header__logo">
            {main.icon && <Icon name={main.icon as IconName} size="xxxl" style={{ marginTop }} />}
            {main.img && <img className="page-header__img" src={main.img} alt={`logo of ${main.text}`} />}
          </span>
          <div className="page-header__info-block">
            {renderTitle(main.text, main.breadcrumbs ?? [])}
            {main.subTitle && <div className="page-header__sub-title">{main.subTitle}</div>}
          </div>
        </div>
      </div>
      <div className="col-sm-2">
        <IconButton
          className="pull-right m-t-2"
          tooltip="Job Approvals"
          tooltipPlacement="bottom"
          name="bell"
          size="lg"
          onClick={jobNotifications}
          style={{ marginTop }}
        />
      </div>
    </div>
  );
}

function renderTitle(title: string, breadcrumbs: NavModelBreadcrumb[]) {
  if (!title && (!breadcrumbs || breadcrumbs.length === 0)) {
    return null;
  }

  if (!breadcrumbs || breadcrumbs.length === 0) {
    return <h1 className="page-header__title">{title}</h1>;
  }

  const breadcrumbsResult = [];
  for (const bc of breadcrumbs) {
    if (bc.url) {
      breadcrumbsResult.push(
        <a className="page-header__link" key={breadcrumbsResult.length} href={bc.url}>
          {bc.title}
        </a>
      );
    } else {
      breadcrumbsResult.push(<span key={breadcrumbsResult.length}> / {bc.title}</span>);
    }
  }
  breadcrumbsResult.push(<span key={breadcrumbs.length + 1}> / {title}</span>);

  return <h1 className="page-header__title">{breadcrumbsResult}</h1>;
}

const getStyles = (theme: GrafanaTheme2) => ({
  headerCanvas: css`
    background: ${theme.colors.background.canvas};
  `,
});

export default PageHeader;
