import { createContext } from 'react';
// eslint-disable-next-line @typescript-eslint/naming-convention
interface IContextValue {
  sourceNodeId: any;
  showSideBar: boolean;
  sideBarPosition: number;
  onShowSideBar: (id: any) => void;
  setSideBarPosition: (val: number) => void;
  onHideSideBar: () => void;
  editorCols: string;
}

const AIMLEditorContext = createContext<IContextValue>({
  sourceNodeId: null,
  showSideBar: false,
  sideBarPosition: -400,
  editorCols: 'col-sm-12',
  onHideSideBar: () => {},
  onShowSideBar: (id: any) => {},
  setSideBarPosition: (val: number) => {},
});

export default AIMLEditorContext;
